import _readableStream from "readable-stream";
import _inherits from "inherits";
import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;
var Duplex = _readableStream.Duplex;
var PassThrough = _readableStream.PassThrough;
var Readable = _readableStream.Readable;
var inherits = _inherits;
var nextTick = typeof process.nextTick !== "undefined" ? process.nextTick : process.nextTick;
exports = Pipeline;
inherits(Pipeline, Duplex);

exports.obj = function (streams, opts) {
  if (!opts && !Array.isArray(streams)) {
    opts = streams;
    streams = [];
  }

  if (!streams) streams = [];
  if (!opts) opts = {};
  opts.objectMode = true;
  return new Pipeline(streams, opts);
};

function Pipeline(streams, opts) {
  if (!((this || _global) instanceof Pipeline)) return new Pipeline(streams, opts);

  if (!opts && !Array.isArray(streams)) {
    opts = streams;
    streams = [];
  }

  if (!streams) streams = [];
  if (!opts) opts = {};
  Duplex.call(this || _global, opts);
  var self = this || _global;
  (this || _global)._options = opts;
  (this || _global)._wrapOptions = {
    objectMode: opts.objectMode !== false
  };
  (this || _global)._streams = [];

  (this || _global).splice.apply(this || _global, [0, 0].concat(streams));

  this.once("finish", function () {
    self._notEmpty();

    self._streams[0].end();
  });
}

Pipeline.prototype._read = function () {
  var self = this || _global;

  this._notEmpty();

  var r = (this || _global)._streams[(this || _global)._streams.length - 1];
  var buf,
      reads = 0;

  while ((buf = r.read()) !== null) {
    Duplex.prototype.push.call(this || _global, buf);
    reads++;
  }

  if (reads === 0) {
    var onreadable = function () {
      r.removeListener("readable", onreadable);
      self.removeListener("_mutate", onreadable);

      self._read();
    };

    r.once("readable", onreadable);
    self.once("_mutate", onreadable);
  }
};

Pipeline.prototype._write = function (buf, enc, next) {
  this._notEmpty();

  (this || _global)._streams[0]._write(buf, enc, next);
};

Pipeline.prototype._notEmpty = function () {
  var self = this || _global;
  if ((this || _global)._streams.length > 0) return;
  var stream = new PassThrough((this || _global)._options);
  stream.once("end", function () {
    var ix = self._streams.indexOf(stream);

    if (ix >= 0 && ix === self._streams.length - 1) {
      Duplex.prototype.push.call(self, null);
    }
  });

  (this || _global)._streams.push(stream);

  (this || _global).length = (this || _global)._streams.length;
};

Pipeline.prototype.push = function (stream) {
  var args = [(this || _global)._streams.length, 0].concat([].slice.call(arguments));

  (this || _global).splice.apply(this || _global, args);

  return (this || _global)._streams.length;
};

Pipeline.prototype.pop = function () {
  return this.splice((this || _global)._streams.length - 1, 1)[0];
};

Pipeline.prototype.shift = function () {
  return this.splice(0, 1)[0];
};

Pipeline.prototype.unshift = function () {
  (this || _global).splice.apply(this || _global, [0, 0].concat([].slice.call(arguments)));

  return (this || _global)._streams.length;
};

Pipeline.prototype.splice = function (start, removeLen) {
  var self = this || _global;
  var len = (this || _global)._streams.length;
  start = start < 0 ? len - start : start;
  if (removeLen === undefined) removeLen = len - start;
  removeLen = Math.max(0, Math.min(len - start, removeLen));

  for (var i = start; i < start + removeLen; i++) {
    if (self._streams[i - 1]) {
      self._streams[i - 1].unpipe(self._streams[i]);
    }
  }

  if (self._streams[i - 1] && self._streams[i]) {
    self._streams[i - 1].unpipe(self._streams[i]);
  }

  var end = i;
  var reps = [],
      args = arguments;

  for (var j = 2; j < args.length; j++) (function (stream) {
    if (Array.isArray(stream)) {
      stream = new Pipeline(stream, self._options);
    }

    stream.on("error", function (err) {
      err.stream = this || _global;
      self.emit("error", err);
    });
    stream = self._wrapStream(stream);
    stream.once("end", function () {
      var ix = self._streams.indexOf(stream);

      if (ix >= 0 && ix === self._streams.length - 1) {
        Duplex.prototype.push.call(self, null);
      }
    });
    reps.push(stream);
  })(arguments[j]);

  for (var i = 0; i < reps.length - 1; i++) {
    reps[i].pipe(reps[i + 1]);
  }

  if (reps.length && self._streams[end]) {
    reps[reps.length - 1].pipe(self._streams[end]);
  }

  if (reps[0] && self._streams[start - 1]) {
    self._streams[start - 1].pipe(reps[0]);
  }

  var sargs = [start, removeLen].concat(reps);

  var removed = self._streams.splice.apply(self._streams, sargs);

  for (var i = 0; i < reps.length; i++) {
    reps[i].read(0);
  }

  this.emit("_mutate");
  (this || _global).length = (this || _global)._streams.length;
  return removed;
};

Pipeline.prototype.get = function () {
  if (arguments.length === 0) return undefined;
  var base = this || _global;

  for (var i = 0; i < arguments.length; i++) {
    var index = arguments[i];

    if (index < 0) {
      base = base._streams[base._streams.length + index];
    } else {
      base = base._streams[index];
    }

    if (!base) return undefined;
  }

  return base;
};

Pipeline.prototype.indexOf = function (stream) {
  return (this || _global)._streams.indexOf(stream);
};

Pipeline.prototype._wrapStream = function (stream) {
  if (typeof stream.read === "function") return stream;
  var w = new Readable((this || _global)._wrapOptions).wrap(stream);

  w._write = function (buf, enc, next) {
    if (stream.write(buf) === false) {
      stream.once("drain", next);
    } else nextTick(next);
  };

  return w;
};

export default exports;
export const obj = exports.obj;